import React from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './Card';
import { Button } from './Button';

const TranquilizerDroneProduct = () => {
  return (
    <Card className="max-w-md mx-auto shadow-lg bg-slate-50">
      <CardHeader className="bg-slate-200">
        <CardTitle className="text-2xl font-bold text-center text-slate-800">High Precision Tranquilizer Darting Drone</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col items-center pt-6">
        <div className="bg-gray-200 p-2 rounded-lg mb-4 overflow-hidden">
          <img 
            src="/Videos/ADD.png" 
            alt="Surveillance Drone" 
            className="w-full h-auto object-cover"
          />
        </div>
        <CardDescription className="text-lg font-semibold text-center mb-4 text-slate-700">
          Accurate. Efficient. Effective.
        </CardDescription>
        <p className="text-sm text-center mb-4 text-slate-600">
          Lock onto targets with AI precision and deploy tranquilizers with ease. Built for rugged terrain and high-stakes missions.
        </p>
        <ul className="list-disc list-inside text-sm mb-4 text-slate-700">
          <li>3 darts per mission</li>
          <li>15-minute flight time</li>
          <li>2km range</li>
          <li>AI-powered target locking</li>
          <li>Rugged design for challenging environments</li>
        </ul>
        <p className="text-sm font-semibold text-center text-slate-700">
          Perfect for wildlife control, tactical ops, and beyond
        </p>
      </CardContent>
      <CardFooter className="flex justify-center bg-slate-200">
      <a href="https://forestsnews.cifor.org/83243/how-to-use-drones-with-respect-for-people-and-wildlife?fnl=en" target="_blank" rel="noopener noreferrer">
  <Button className="bg-blue-600 text-white hover:bg-blue-700">
    Learn More
  </Button>
</a>
      </CardFooter>
    </Card>
  );
};

export default TranquilizerDroneProduct;