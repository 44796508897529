import React from 'react';
import { Alert, AlertDescription, AlertTitle } from './Alert';
import { Card, CardContent, CardHeader, CardTitle } from './Card';
import { Separator } from './Separator';
import { Link } from 'react-router-dom';
import Header_for_all from './Header_for_all';


const VideoContainer = ({ videoId }) => (
  <div className="w-full aspect-video rounded-lg overflow-hidden shadow-md">
    <iframe
      className="w-full h-full"
      src={`https://www.youtube.com/embed/${videoId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  </div>
);

const Section = ({ title, videoIds }) => (
  <Card className="w-full">
    <CardHeader>
      <CardTitle className="text-xl font-bold text-gray-800">{title}</CardTitle>
    </CardHeader>
    <CardContent>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {videoIds.map((id, index) => (
          <VideoContainer key={index} videoId={id} />
        ))}
      </div>
    </CardContent>
  </Card>
);


const HumanWildlifeConflictBlog1 = () => {
  const sections = [
    { title: "Human-Elephant Conflicts", videoIds: ["HE69a4j1deo", "PLACEHOLDER_26", "PLACEHOLDER_27"] },
    // { title: "Human-Wildlife Conflicts in Ghana", videoIds: ["PLACEHOLDER_25", "PLACEHOLDER_26", "PLACEHOLDER_27"] },
    // { title: "Human-Wildlife Conflicts in Benin", videoIds: ["PLACEHOLDER_25", "PLACEHOLDER_26", "PLACEHOLDER_27"] },
    
  ];

  const buttonClasses = 'bg-secondary text-secondary-foreground p-2 rounded transition duration-500 ease-in-out hover:bg-black relative overflow-hidden';

  return (
    <div className="min-h-screen bg-gray-100">
      {/* <Header_for_all/> */}

      <main className="container mx-auto px-4 py-8">
        {/* <Card className="mb-8 bg-white shadow-lg">
          <CardHeader>
            <CardTitle className="text-2xl font-bold text-gray-800">Human-Wildlife Conflict Report</CardTitle>
          </CardHeader>
          <CardContent>
            <Alert className="bg-yellow-50 border-yellow-200">
              <AlertTitle className="text-yellow-800 font-semibold">Problem Encountered:</AlertTitle>
              <AlertDescription className="text-yellow-700">
                Human-Elephant conflict escalates as animals venture beyond protected areas, causing crop damage and economic losses for farmers, while facing habitat fragmentation and retaliatory attacks.
              </AlertDescription>
            </Alert>
          </CardContent>
        </Card> */}

        <Separator className="my-8" />

        {/* <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center underline">1 Problem: </h2> */}
<h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">1.1.1 China: </h2>
{/* <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">1.1.1 India: </h2> */}
        <Section title={sections[0].title} videoIds={sections[0].videoIds} />

        <div className="grid grid-cols-1 gap-8">
          {sections.slice(1).map((section, index) => (
            <Section key={index + 1} title={section.title} videoIds={section.videoIds} />
          ))}
        </div>

        <Separator className="my-8" />
        {/* <h2 className="text-2xl font-bold text-gray-800 text-center underline pb-8">1.1.2 Africa: </h2> */}

        {/* <Card className="bg-white shadow-lg">

          <CardHeader>
            <CardTitle className="text-2xl font-bold text-gray-800">Leave a Note</CardTitle>
          </CardHeader>

          <CardContent>
            <form className="space-y-4" action="https://formspree.io/f/xgvwjyjg" method="POST">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                <input type="text" id="name" name="name" className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Your name" />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                <input type="email" id="email" name="_replyto" className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Your email" />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                <textarea id="message" name="message" rows="4" className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Tell us about your thoughts, reactions, or do you have a better idea?"></textarea>
              </div>
              <div className="flex justify-end">
                <button type="submit" className="bg-green-800 text-white px-4 py-2 rounded-md hover:text-white hover:bg-black">Submit</button>
              </div>
            </form>
          </CardContent>
        </Card> */}
        
      </main>

      {/* <footer className="bg-green-800 text-white py-4 mt-12">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 PSYC Aerospace & Defence. All rights reserved.</p>
        </div>
      </footer> */}
    </div>
  );
};

export default HumanWildlifeConflictBlog1;