import React from 'react';

const Card = ({ children, className }) => (
  <div className={`bg-white shadow-lg rounded-lg overflow-hidden ${className}`}>
    {children}
  </div>
);

const CardHeader = ({ children, className }) => (
  <div className={`p-4 border-b ${className}`}>
    {children}
  </div>
);

const CardTitle = ({ children, className }) => (
  <h2 className={`text-xl font-bold ${className}`}>
    {children}
  </h2>
);

const CardContent = ({ children, className }) => (
  <div className={`p-4 ${className}`}>
    {children}
  </div>
);

const CardDescription = ({ children, className }) => (
  <p className={`text-gray-600 ${className}`}>
    {children}
  </p>
);

const CardFooter = ({ children, className }) => (
  <div className={`p-4 border-t ${className}`}>
    {children}
  </div>
);

export { Card, CardHeader, CardTitle, CardContent, CardDescription, CardFooter };
