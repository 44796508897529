// src/components/ui/Button.js
import React from 'react';

export const Button = ({ children, className, ...props }) => (
  <button
    className={`px-4 py-2 rounded-lg focus:outline-none ${className}`}
    {...props}
  >
    {children}
  </button>
);
