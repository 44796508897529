import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import { motion } from 'framer-motion';

const Header_for_all = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <header className="relative z-20 bg-black text-white shadow-lg">
      <div
        className="container mx-auto flex items-center justify-between h-16 bg-cover bg-center bg-opacity-100 container mx-auto flex items-center justify-between bg-cover bg-center bg-opacity-100"
        style={{ backgroundImage: "url('/Videos/header5.jpeg')" }}
      >
        {/* Logo section */}
        <div className="relative flex items-center">
          <Link to="/" className="ml-16 md:ml-0"> {/* Add margin-left only on small screens */}
            <img src="/Videos/logo_psyc.png" alt="Wildlife Protection Logo" className="w-28 h-38 object-contain" />
          </Link>
        </div>

        {/* Main navigation for larger screens */}
        <nav className="hidden md:flex space-x-4 font-poppins uppercase tracking-widest">
          <NavLink to="/">Home</NavLink>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/products">Products</NavLink>
          <NavLink to="/partners">Partners</NavLink>
          <NavLink to="/careers">Careers</NavLink>
          <NavLink to="/blog">Blog</NavLink>
        </nav>

        {/* Menu Button for Small Screens */}
        <button onClick={toggleMenu} className="md:hidden text-white absolute top-4 left-4 z-30">
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>

      {/* Sliding mobile menu */}
      {isMenuOpen && (
        <motion.nav
          initial={{ x: '-100%', opacity: 0 }}
          animate={{ x: 0, opacity: 0.9 }}
          exit={{ x: '-100%', opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed top-0 left-0 w-3/4 h-full bg-gray-800 bg-opacity-90 shadow-lg flex flex-col items-start p-6 space-y-4 z-30"
        >
          <NavLink to="/" onClick={toggleMenu}>Home</NavLink>
          <NavLink to="/about" onClick={toggleMenu}>About</NavLink>
          <NavLink to="/products" onClick={toggleMenu}>Products</NavLink>
          <NavLink to="/partners" onClick={toggleMenu}>Partners</NavLink>
          <NavLink to="/careers" onClick={toggleMenu}>Careers</NavLink>
          <NavLink to="/blog" onClick={toggleMenu}>Blog</NavLink>
        </motion.nav>
      )}

      {/* Dimmed background when menu is open */}
      {isMenuOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="fixed inset-0 bg-black z-10"
          onClick={toggleMenu}
        />
      )}
    </header>
  );
};

const NavLink = ({ to, children, ...props }) => (
  <Link
    to={to}
    className="relative px-3 py-2 font-popins text-[20px] hover-underline transition-colors duration-200 hover:text-white focus:outline-none"
    {...props}
  >
    {children}
  </Link>
);

export default Header_for_all;
