import React from 'react';
import { motion } from 'framer-motion';
import Header_for_all from './Header_for_all';
import Footer_for_all from './Footer_for_all';
import { Link } from 'react-router-dom';

const bgClasses = 'bg-background text-foreground p-6 bg-black'
const textClasses = 'text-white'
const titleClasse = 'text-xl font-bold'
const linkClasses = 'text-primary'


const Footer = () => {
  return (
    <div className={bgClasses}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <FooterItem title="About PSYC" content="Passive Surveillance Yielding Control - Innovating for a safer future." />
        <FooterItem title="Contact Us" content={<ContactContent />} />
        <FooterItem title="Follow Us" content={<FollowContent />} />
      </div>
      <div className="text-muted-foreground text-center mt-4">&copy; 2024 PSYC. All rights reserved.</div>
    </div>
  )
}

const FooterItem = ({ title, content }) => {
  return (
    <div className={textClasses}>
      <h2 className={titleClasse}>{title}</h2>
      <p>{content}</p>
    </div>
  )
}

const ContactContent = () => {
  return (
    <>
      <p>
        Email:{' '}
        <a href="mailto:psycdefence@gmail.com" className={linkClasses}>
        psycdefence@gmail.com
        </a>
      </p>
      <p>
        Phone:{' '}
        <a href="tel:+918861540565" className={linkClasses}>
          +91 88615 40655
        </a>
      </p>
    </>
  )
}

const FollowContent = () => {
  return (
    <>
      <a href="#" className={linkClasses}>
        Facebook
      </a>
      <br />
      <a href="#" className={linkClasses}>
        Twitter
      </a>
      <br />
      <a href="#" className={linkClasses}>
        LinkedIn
      </a>
    </>
  )
};

const ProductPage = () => {
  return (
    <div>
      <Header_for_all />
      <div className="min-h-screen bg-fixed bg-center bg-cover bg-no-repeat" style={{ backgroundImage: 'url(/Videos/header5.jpeg)' }}>
        {/* bg-gradient-to-b */}
        <div className="from-black via-gray-900 to-black py-8 px-4 sm:py-12 sm:px-6 lg:px-8 bg-opacity-75">
          <section className="text-center mb-12 sm:mb-16">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-4 text-white">Innovative Solutions for Elephant-Wildlife Conflict</h1>
            <p className="text-lg sm:text-xl text-gray-400 mb-6 sm:mb-8">Explore our advanced drone technology and specialized training services.</p>
          </section>

          {/* Products Overview */}
          <section className="text-center mb-12 sm:mb-16">
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 text-white">Our Drones</h2>
            <p className="text-base sm:text-lg text-gray-400 mb-8 sm:mb-12">Our drones are designed to address human-wildlife conflicts effectively.</p>

            <div className="grid gap-6 sm:gap-8 grid-cols-1 md:grid-cols-2">
              {/* Surveillance Drone */}
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="bg-gray-800 p-4 sm:p-6 rounded-lg shadow-lg hover:shadow-xl transition-transform duration-300 hover:scale-105"
              >
                <Link to="/products/AS1">
                  <img src="/Videos/drone1.png" alt="Surveillance Drone" className="w-full h-48 sm:h-64 object-cover rounded-lg mb-4" />
                </Link>
                <h3 className="text-xl sm:text-2xl font-semibold mb-2 text-white">Surveillance & Weight Estimation Drone</h3>
                <p className="text-sm sm:text-base text-gray-400 mb-4">
                  Advanced camera system for real-time monitoring. Long battery life for extended surveillance missions. Lightweight and easy to deploy.
                </p>
                <ul className="text-left text-sm sm:text-base text-gray-400 mb-4">
                  <li><strong>Camera:</strong> 1080p resolution/ Day and night visibility</li>
                  <li><strong>Battery Life:</strong> Up to 40 mins</li>
                  <li><strong>Range:</strong> 2 km</li>
                </ul>
                <button className="w-full sm:w-auto px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors duration-300">Pre-Order</button>
              </motion.div>

              {/* Tranquilizing Drone */}
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="bg-gray-800 p-4 sm:p-6 rounded-lg shadow-lg hover:shadow-xl transition-transform duration-300 hover:scale-105"
              >
                <Link to="/products/AS2">
                  <img src="/Videos/dartingDrone.png" alt="Darting Drone" className="w-full h-48 sm:h-64 object-cover rounded-lg mb-4" />
                </Link>
                <h3 className="text-xl sm:text-2xl font-semibold mb-2 text-white">Darting Drone</h3>
                <p className="text-sm sm:text-base text-gray-400 mb-4">
                  Equipped with a precise tranquilizer delivery system. Integrated with advanced AI target locking system for accurate deployment. Durable build for rugged environments.
                </p>
                <ul className="text-left text-sm sm:text-base text-gray-400 mb-4">
                  <li><strong>Tranquilizer Capacity:</strong> 3 darts in one mission</li>
                  <li><strong>Battery Life:</strong> Up to 15 mins</li>
                  <li><strong>Range:</strong> 2 km</li>
                </ul>
                <button className="w-full sm:w-auto px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors duration-300">Pre-Order</button>
              </motion.div>
            </div>
          </section>

          {/* Services Overview */}
          <section className="text-center mb-12 sm:mb-16">
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 text-white">Our Services</h2>
            <p className="text-base sm:text-lg text-gray-400 mb-8 sm:mb-12">We offer specialized training services to ensure safe and effective drone operations.</p>

            <div className="grid gap-6 sm:gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
              {/* Drone Services & Maintenance */}
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="bg-gray-800 p-4 sm:p-6 rounded-lg shadow-lg hover:shadow-xl transition-transform duration-300 hover:scale-105"
              >
                <img src="/Videos/droneServices.jpeg" alt="Drone Services & Maintenance" className="w-full h-48 sm:h-72 object-cover rounded-lg mb-4" />
                <h3 className="text-lg sm:text-xl font-semibold mb-2 text-white">Drone Services & Maintenance</h3>
                <p className="text-sm sm:text-base text-gray-400 mb-4">
                  Comprehensive drone maintenance and repair services. Regular servicing to ensure optimal performance.
                </p>
                <button className="w-full sm:w-auto px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors duration-300">Learn More</button>
              </motion.div>

              {/* Darting Drone Pilot Training */}
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="bg-gray-800 p-4 sm:p-6 rounded-lg shadow-lg hover:shadow-xl transition-transform duration-300 hover:scale-105"
              >
                <img src="/Videos/droneTrain.jpeg" alt="Darting Drone Pilot Training" className="w-full h-48 sm:h-72 object-cover rounded-lg mb-4" />
                <h3 className="text-lg sm:text-xl font-semibold mb-2 text-white">Book a Pilot For Your Mission</h3>
                <p className="text-sm sm:text-base text-gray-400 mb-4">
                  Specialized training for operating darting drones. Hands-on experience with real-world scenarios.
                </p>
                <button className="w-full sm:w-auto px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors duration-300">Book Now</button>
              </motion.div>

              {/* Get Your Own License */}
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="bg-gray-800 p-4 sm:p-6 rounded-lg shadow-lg hover:shadow-xl transition-transform duration-300 hover:scale-105"
              >
                <img src="/Videos/droneLic.jpeg" alt="Get Your Own License" className="w-full h-72 sm:h-72 object-cover rounded-lg mb-4" />
                <h3 className="text-lg sm:text-xl font-semibold mb-2 text-white">Get Your Own License</h3>
                <p className="text-sm sm:text-base text-gray-400 mb-4">
                  Comprehensive course to get your darting drone pilot license. Learn the rules and regulations.
                </p>
                <button className="w-full sm:w-auto px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors duration-300">Enroll Now</button>
              </motion.div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
      <Footer_for_all />
    </div>
  );
};


export default ProductPage;