import React, { useState, useEffect } from 'react';
import { Alert, AlertDescription, AlertTitle } from './Alert';
import { Card, CardContent, CardHeader, CardTitle } from './Card';
import { Separator } from './Separator';
import { Link } from 'react-router-dom';
import Header_for_all from './Header_for_all';
import HumanWildlifeConflictBlog1 from './Blog1';
import HumanWildlifeConflictBlog2 from './BlogAfrica';
import HumanWildlifeConflictBlog1F from './BlogFail0';
import HumanWildlifeConflictBlog2F from './BlogFail1';
import HumanWildlifeConflictBlogConf2 from './BlogConf0';
import { ChevronUp, ChevronDown } from 'lucide-react';


const VideoContainer = ({ videoId }) => (
  <div className="w-full aspect-video rounded-lg overflow-hidden shadow-md">
    <iframe
      className="w-full h-full"
      src={`https://www.youtube.com/embed/${videoId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  </div>
);

const Section = ({ title, videoIds }) => (
  <Card className="w-full">
    <CardHeader>
      <CardTitle className="text-xl font-bold text-gray-800">{title}</CardTitle>
    </CardHeader>
    <CardContent>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {videoIds.map((id, index) => (
          <VideoContainer key={index} videoId={id} />
        ))}
      </div>
    </CardContent>
  </Card>
);

const HumanWildlifeConflictBlog = () => {
  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 10) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const sections = [
    { title: "Human-Elephant Conflicts in Karnataka", videoIds: ["t5E7Kii98uo", "QEpckTci8eg", "0BWcYxKE_FQ"] },
    { title: "Human-Elephant Conflicts in Kerala", videoIds: ["Nu330qWqDvY", "fSqDGqeInoc", "D8QRcrdNoQk"] },
    { title: "Human-Elephant Conflicts in Assam", videoIds: ["2vKKqoolyJ8", "ILKhR5GDn8U", "ILKhR5GDn8U"] },
    { title: "Human-Elephant Conflicts in Andhra Pradesh", videoIds: ["PLACEHOLDER_10", "PLACEHOLDER_11", "PLACEHOLDER_12"] },
    { title: "Human-Elephant Conflicts in Telangana", videoIds: ["PLACEHOLDER_13", "PLACEHOLDER_14", "PLACEHOLDER_15"] },
    { title: "Human-Elephant Conflicts in Maharashtra", videoIds: ["fJh4gFke84M", "FJC0rLl1eek", "bKRjEp0DCWo"] },
    { title: "Human-Elephant Conflicts in Madhya Pradesh", videoIds: ["CgCgnp2epq0", "6cHJrfqzczc", "WHVwd91pzKg"] },
    { title: "Human-Elephant Conflicts in Odisha", videoIds: ["zzqYRdo_SNY", "PLACEHOLDER_23", "PLACEHOLDER_24"] },
    { title: "Human-Elephant Conflicts in West Bengal", videoIds: ["PLACEHOLDER_25", "PLACEHOLDER_26", "PLACEHOLDER_27"] },
  ];

const buttonClasses = 'fixed bottom-6 right-6 bg-gray-800 text-white p-3 rounded-full shadow-lg transition duration-300 ease-in-out hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500';

  return (
    <div className="min-h-screen bg-gray-100">
      <Header_for_all />

      <main className="container mx-auto px-4 py-8">
        <Card className="mb-8 bg-white shadow-lg">
          <CardHeader>
            <CardTitle className="text-2xl font-bold text-gray-800">Human-Elephant Conflict Report</CardTitle>
          </CardHeader>
          <CardContent>
            <Alert className="bg-yellow-50 border-yellow-200">
              <AlertTitle className="text-yellow-800 font-semibold">Problem Encountered:</AlertTitle>
              <AlertDescription className="text-yellow-700">
                Human-Elephant conflict escalates as animals venture beyond protected areas, causing crop damage and economic losses for farmers, while facing habitat fragmentation and retaliatory attacks.
              </AlertDescription>
            </Alert>
          </CardContent>
        </Card>

        <Separator className="my-8" />

        <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center underline">1 Problem: </h2>
        <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">1.1 Asia: </h2>
        <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">1.1.1 India: </h2>
        <Section title={sections[0].title} videoIds={sections[0].videoIds} />

        <div className="grid grid-cols-1 gap-8">
          {sections.slice(1).map((section, index) => (
            <Section key={index + 1} title={section.title} videoIds={section.videoIds} />
          ))}
        </div>
        <HumanWildlifeConflictBlog1 />
        <HumanWildlifeConflictBlog2 />
        <HumanWildlifeConflictBlog1F />
        <HumanWildlifeConflictBlog2F />
        <HumanWildlifeConflictBlogConf2 />

        <Separator className="my-8" />

        <Card className="bg-white shadow-lg">
          <CardHeader>
            <CardTitle className="text-2xl font-bold text-gray-800">Leave a Note</CardTitle>
          </CardHeader>

          <CardContent>
            <form className="space-y-4" action="https://formspree.io/f/xgvwjyjg" method="POST">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                <input type="text" id="name" name="name" className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Your name" />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                <input type="email" id="email" name="_replyto" className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Your email" />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                <textarea id="message" name="message" rows="4" className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Tell us about your thoughts, reactions, or do you have a better idea?"></textarea>
              </div>
              <div className="flex justify-end">
                <button type="submit" className="bg-green-800 text-white px-4 py-2 rounded-md hover:text-white hover:bg-black">Submit</button>
              </div>
            </form>
          </CardContent>
        </Card>
      </main>

      <footer className="bg-green-800 text-white py-4 mt-12">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 PSYC Aerospace & Defence. All rights reserved.</p>
        </div>
      </footer>

      <button
    onClick={isBottom ? scrollToTop : scrollToBottom}
    className={buttonClasses}
    aria-label={isBottom ? 'Scroll to top' : 'Scroll to bottom'}
  >
    {isBottom ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
  </button>
    </div>
  );
};

export default HumanWildlifeConflictBlog;
