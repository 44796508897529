import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import AboutUs from './AboutUs';
// import Careers from './Careers';
import HumanWildlifeConflictBlog from './Blog';
import Partners from './Partners';
import ProductPage from './Products';
import SurveillanceDroneProduct from './AS1';
import TranquilizerDroneProduct from './AS2';
import Careers from './CareersP';

const App = () => (
    <Router>
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/blog" element={<HumanWildlifeConflictBlog />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/products" element={<ProductPage />} />
            <Route path="/products/AS1" element={<SurveillanceDroneProduct />} />
            <Route path="/products/AS2" element={<TranquilizerDroneProduct/>} />
        </Routes>
    </Router>
);

export default App;
