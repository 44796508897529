import React from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './Card';
import { Button } from './Button';

const SurveillanceDroneProduct = () => {
  return (
    <Card className="max-w-md mx-auto shadow-lg">
      <CardHeader>
        <CardTitle className="text-2xl font-bold text-center">Advanced Surveillance Drone</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col items-center">
        <div className="bg-gray-200 p-2 rounded-lg mb-4 overflow-hidden">
          <img 
            src="/Videos/AS.png" 
            alt="Surveillance Drone" 
            className="w-full h-auto object-cover"
          />
        </div>
        <CardDescription className="text-sm text-center mb-4">
          Introducing the Ultimate Surveillance Solution:
          The portable and lightweight advanced camera system offers real-time monitoring capabilities, ideal for extended surveillance missions.
        </CardDescription>
        <ul className="list-disc list-inside text-sm mb-4">
          <li>High-definition 1080p resolution</li>
          <li>Day and night visibility</li>
          <li>Long-lasting battery life of up to 40 minutes</li>
          <li>Extended range of 2 kilometers</li>
        </ul>
        <p className="text-sm font-semibold text-center">
          Deploy effortlessly and capture critical footage when and where you need it.
        </p>
      </CardContent>
      <CardFooter className="flex justify-center">
      <a href="https://www.wti.org.in/news/drones-for-wildlife-conservation/" target="_blank" rel="noopener noreferrer">
  <Button className="bg-blue-600 text-white hover:bg-blue-700">
    Learn More
  </Button>
</a>
      </CardFooter>
    </Card>
  );
};

export default SurveillanceDroneProduct;