import React from 'react';
import { Link } from 'react-router-dom';
import Header_for_all from './Header_for_all';
import Footer_for_all from './Footer_for_all';
import { Menu, X } from 'lucide-react';
import { motion } from 'framer-motion';




const NavLink = ({ to, children, ...props }) => (
  <Link
    to={to}
    className="relative px-3 py-2 rounded-md font-extrabold text-[20px] transition-colors duration-200 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
    {...props}
  >
    {children}
    <span className="absolute inset-x-0 bottom-0 h-0.5 bg-white transition-all duration-700 transform scale-x-0 origin-center hover:scale-x-100"></span>
  </Link>
);

const SHARED_STYLES = {
  header: 'absolute top-0 left-0 right-0 flex justify-between items-center p-4 bg-black bg-opacity-100',
  textWhite: 'text-white',
  textCenter: 'text-center'
};

// const MainContent = () => {
//   return (
//     <div className="flex items-center justify-center h-full pt-56">
//       <h2 className={`${SHARED_STYLES.textWhite} text-4xl pt-40 font-bold ${SHARED_STYLES.textCenter}`}>
//         <div className='bg-black opacity-50'>
//           <div className='opacity-100'>One pulse, One Vision, One passion</div>
//         </div>
//       </h2>
//     </div>
//   );
// };

const VisionMissionComponent = () => {
  return (
    <div className="flex flex-col lg:flex-row h-auto lg:h-screen">
      {/* Left half - Text content */}
      <div className="w-full lg:w-1/2 p-6 sm:p-12 overflow-y-auto bg-gray-900 text-white shadow-inner relative">
        <div className="absolute inset-0 opacity-20" style={{ backgroundImage: 'url(path/to/elephant-texture.jpg)', backgroundSize: 'cover' }}></div>
        <div className="relative z-10 max-w-xl mx-auto">
          <h1 className="text-2xl sm:text-4xl font-bold mb-4 sm:mb-8 text-center text-white border-b-2 border-white pb-4">Our Company</h1>

          <section className="mb-8 sm:mb-12">
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 text-white underline">Our Vision:</h2>
            <p className="text-sm sm:text-lg text-gray-300 leading-relaxed">
              To lead in defense with cutting-edge UAV technology, championing Atmanirbhar Bharat and
              the Make in India initiative to protect people and wildlife through innovative, non-lethal solutions.
            </p>
          </section>
          
          <section>
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 text-white underline">Our Mission:</h2>
            <p className="text-sm sm:text-lg text-gray-300 leading-relaxed">
              To transform passive defense with state-of-the-art UAV systems, delivering precise and ethical
              solutions that enhance safety and preserve the natural balance.
            </p>
          </section>
          
          <div className="mt-8 sm:mt-12 bg-gray-800 rounded-lg shadow-md p-4 sm:p-6 border-l-4 border-white">
            <p className="text-xs sm:text-sm text-gray-400 italic">
              Committed to innovation, sustainability, and ethical practices in technology for protecting wildlife.
            </p>
          </div>
        </div>
      </div>
      
      {/* Right half - Image */}
      <div className="w-full bg-gray-800 lg:w-1/2 px-2 sm:pl-2 sm:pr-2 sm:py-5 ">
        <div className="bg-gray-800 px-1 shadow-lg rounded-lg max-h-96 lg:max-h-screen">
          <img 
            src="/Videos/mission.jpeg" 
            alt="Elephants with drones" 
            className="w-full h-auto object-cover rounded-lg max-h-80 lg:pt-28 lg:max-h-screen"
          />
        </div>
      </div>

    </div>
  );
};

const MainContent = () => {
  return (
    <div className="flex items-center justify-center h-full pt-32 sm:pt-56">
      <h2 className={`${SHARED_STYLES.textWhite} text-2xl sm:text-4xl pt-40 font-bold ${SHARED_STYLES.textCenter}`}>
        <div className='bg-black opacity-50'>
          <div className='opacity-100'>One pulse, One Vision, One passion</div>
        </div>
      </h2>
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="bg-black text-white z-50 py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
          <div>
            <h3 className="text-lg sm:text-xl font-semibold mb-4">About PSYC</h3>
            <p className="text-gray-400 text-sm sm:text-base">
              Passive Surveillance Yielding Control - Innovating for a safer future.
            </p>
          </div>
          
          <div>
            <h3 className="text-lg sm:text-xl font-semibold mb-4">We are Hiring</h3>
            <a 
              href="https://forms.gle/PSnx1UhYtCfWhaLQ6" 
              className="inline-block bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded transition duration-300 text-sm sm:text-base"
            >
              Apply Now
            </a>
          </div>
          
          <div>
            <h3 className="text-lg sm:text-xl font-semibold mb-4">Follow Us</h3>
            <ul className="space-y-2">
              <li><a href="#" className="text-gray-400 hover:text-white text-sm sm:text-base">Facebook</a></li>
              <li><a href="#" className="text-gray-400 hover:text-white text-sm sm:text-base">Twitter</a></li>
              <li><a href="#" className="text-gray-400 hover:text-white text-sm sm:text-base">LinkedIn</a></li>
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="mt-8 border-t border-gray-700 pt-4 text-center text-sm text-gray-500">
        © 2024 PSYC. All rights reserved.
      </div> */}
    </footer>
  );
};

const AboutUsPage = () => {
  return (
    <div>
      <div className="relative min-h-screen overflow-hidden">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          autoPlay
          loop
          muted
        >
          <source src="/Videos/about_us.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Header_for_all />
        <MainContent />
      </div>
      <VisionMissionComponent />
      
      <Footer />
      <Footer_for_all />
    </div>
  );
};

export default AboutUsPage;
