const Footer_for_all = () => {
    return (
      <footer className="bg-green-800 text-white py-4 "  style={{ backgroundImage: "url('/Videos/header5.jpeg')" }}>
        <div className="container mx-auto px-4 text-center text-white text-bold">
          <p>&copy; 2024 PSYC Aerospace & Defence. All rights reserved.</p>
        </div>
      </footer>
    );
  };
  
  export default Footer_for_all;
  